import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          If you want to make 1.000.000 USD from 1 USD invested you came to the right place!
        </p>
        <p>
          With days will be driving a Lambo!!!
        </p>
        <p>
          Send your money to the following address and we will make 1.000.000 from import
        </p>
        <p>
        0x08155E189a894B6E21031542379077F78061FD1923
        </p>
      </header>
    </div>
  );
}

export default App;
